<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>李勤</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          李勤：“春锋里”共富工坊负责人。海曙听力残障人士，1970年出生，小时候因发烧打针导致听力二级残疾，小学毕业后，帮助父母种水稻、蔺草，编草帽，曾经跟着当裁缝的大姐学习缝纫技术，掌握了一门好手艺。前几年领着低保和残疾人困难生活补助金，家庭经济并不宽裕。李勤自强不息，刻苦钻研，开始制作眼罩、口罩、布艺等手工艺品，并拿到公益集市和“灿益工坊·印象宁波”残疾人文创产品展示展销中心售卖。由于设计新颖、惹人喜爱，她的手工艺品逐渐打开了销路。
        </p>
      </div>

      <div class="main-video">
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introducePageThree_bg.mp3"
        ></audio>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageThree_show_one.jpg" al t="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    top: 160px;
    left: 72px;
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    letter-spacing: 40px;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
